<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="PROFESSIONAL SERVICES"
          :breadcrumb="[
            {
              label: 'Professionals',
            },
            { label: 'Professional-Services' },
          ]"
        >
        </banner>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card radius"
              style="box-shadow: 0px 0px 10px gray; margin-left: 3px; height: 100%"
            >
              <div class="card-body">
                <!-- start list area  -->
                <div class="row">
                  <div class="col-md-6 col-6">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder">All Services </b>
                    </h4>
                  </div>
                  <div class="col-md-6 col-6 text-end">
                    <button
                      type="button"
                      style="background-color: #f21300"
                      class="btn btn-sm text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Add Services
                    </button>
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>+</th>

                          <th>Code</th>
                          <th>Mobile</th>
                          <th>Email</th>
                          <th>Designation</th>
                          <th>Service</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>

                        <tbody>
                          <tr
                            v-for="(service, index) in professionalservices"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>
                            <td>{{ service.professional.code }}</td>
                            <td>{{ service.professional.phone }}</td>
                            <td>{{ service.professional.email }}</td>
                            <td>----</td>
                            <td>{{ service.service.name }}</td>
                            <td>
                              <p
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 50px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                ------
                              </p>
                            </td>
                            <td>
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>

                                <button
                                  type="button"
                                  class="btn btns btn-sm btn-danger"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="trash" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!--start model  -->
  <div
    class="modal font fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style="border-radius: 50px"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="border-radius: 1.4vw">
        <div class="modal-header bg-white" style="border-radius: 1.4vw">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b>New Profassional</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-xl-4 col-md-4 col-sm-4 col-12" style="padding-right: 5px">
                <div class="from-group">
                  <select class="form-select" aria-label="Default select example">
                    <option selected>Profassional type</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-4 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <input type="tel" class="form-control" placeholder="Code" />
                </div>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-4 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <input type="text" class="form-control" placeholder="Name" />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group">
                  <input type="number" class="form-control" placeholder="Phone " />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Alternative Phone"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-xl-4 col-md-4 col-sm-4 col-12" style="padding-right: 5px">
                <div class="from-group">
                  <input type="email" class="form-control" placeholder="Email " />
                </div>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-4 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <input type="password" class="form-control" placeholder="Password" />
                </div>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-4 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Confirm Password"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group">
                  <input type="text" class="form-control" placeholder="Specialization " />
                </div>
              </div>

              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <input
                    placeholder="Date of Birth"
                    class="textbox-n form-control"
                    type="text"
                    onfocus="(this.type='date')"
                    id="date"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-4 col-md-4 col-sm-4 col-12" style="padding-right: 5px">
                <div class="from-group">
                  <select class="form-select" aria-label="Default select example">
                    <option selected>States</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </select>
                </div>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-4 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <input type="number" class="form-control" placeholder=" Pin" />
                </div>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-4 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <select class="form-select" aria-label="Default select example">
                    <option selected>Login Status</option>
                    <option value="1">Active</option>
                    <option value="2">In Active</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-6 col-md-6 col-sm-6 col-6">
                <div class="from-group">
                  <input type="number" class="form-control" placeholder="Address" />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <select class="form-select" aria-label="Default select example">
                    <option selected>Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="from-group">
                  <textarea class="form-control" rows="4" placeholder="Remark"></textarea>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->
</template>

//
<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
// import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Professional Services",

  components: {
    Banner,
  },
  data() {
    return {
      professionalservices: [],
    };
  },
  methods: {
    loadProfessionalService() {
      this.$axios
        .get("superadmin/professionalservice", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("______________________________======--------");
          console.log(res.data.data.data);
          this.professionalservices = res.data.data.data;
          console.log("______________________________======--------");
        });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadProfessionalService();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  width: 95%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 8px gray;
  line-height: 10%;
  margin-left: 13px;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
